import React from 'react'
import Carousel from 'react-bootstrap/Carousel'

import image1 from './images/image1.jpg'
import image2 from './images/image2.jpg'
import image3 from './images/image3.jpg'
import image4 from './images/image4.jpg'

import { Text } from "./Text";

class Slide extends React.Component {
  render() {
    return(
      <section id='slide'>
        <Carousel>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src={image1}
              alt="second slide"
            />

            <Carousel.Caption>
              <p className="texto-slide"><Text tid='carousel.development'/></p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src={image2}
              alt="second slide"
            />

            <Carousel.Caption>
              <p className="texto-slide"><Text tid='carousel.offices'/></p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src={image3}
              alt="Third slide"
            />

            <Carousel.Caption>
              <p className="texto-slide"><Text tid='carousel.quality'/></p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src={image4}
              alt="Third slide"
            />

            <Carousel.Caption>
              <p className="texto-slide-azul"><Text tid='carousel.commitment'/></p>
            </Carousel.Caption>
          </Carousel.Item>

        </Carousel>

      </section>
    )
  }

}

export default Slide;