import React from 'react'
import instagram from "./images/instagram.png";
import facebook from "./images/facebook.png";
import footer from "./images/footer.png";
import footer2 from "./images/footer2.png";

import {Text} from "./Text";

class Footer extends React.Component {
  render() {
    return(
      <section >

        <div className="mx-auto pt-5 bg-footer text-center">
          <div className="container text-center">
            <img src={footer2} alt='glass'/>

            <div className="row  text-left mx-auto  mt-5 ">
              <div className="col-xs-12 col-sm-12 col-md-4 mb-4 pl-5 ml-5 ">
                <p className="navbar-light text-white"><Text tid='social.title'/></p>
                <div className="text-white mb-2">
                  <a href="https://www.instagram.com/pryme_group/"> <img className="mr-3" src={instagram} alt='instagram'/> </a>
                  <a className='social-link' href="https://www.instagram.com/pryme_group/" target="_blank" rel="noopener noreferrer">Instagram</a>
                </div>
                <div className="text-white">
                  <a href="https://www.facebook.com/prymegroup/"> <img className="mr-3" src={facebook} alt='facebook'/> </a>
                  <a className='social-link' href="https://www.facebook.com/prymegroup/" target="_blank" rel="noopener noreferrer">Facebook</a>
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-3 mb-4 ">
              </div>

              <div className="col-xs-12 col-sm-12 col-md-3 mb-4 text-center">
                <div >
                  <img className="mb-2"src={footer} alt='glass'/>
                </div>
                <div >
                  <a className="btn btn-yellow btn-lg mb-2" href="#contact"><Text tid='cta.go_to_contact2'/></a>
                </div>
              </div>


            </div>
            <div className="row  text-center pb-2 ">
              <div className="col-xs-12 col-sm-12 col-md-12 ">
                <hr className="line"></hr>
                <p className="text-white text-center mx-auto py-4">© 2020 Prymegroup. Todos los Derechos Reservados. By <a className='brutal-link' href="http://brutalrocks.com/" target="_blank" rel="noopener noreferrer">Brutal</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Footer;
