import React from 'react';
import Home from "./Home";

import { LanguageProvider } from "./languages/language_provider";

function App() {
  return (
    <LanguageProvider>
      <div>
        <Home/>
      </div>
    </LanguageProvider>
  );
}

export default App;
