import React from 'react';

import Header from './Header'
import Slide from './Slide'
import About from './About'
import Services from './Services'
import Customers from './Customers'
import Management from './Management'
import Contact from './Contact'
import Footer from './Footer'

import 'animate.css';
import './index.css'

class Home extends React.Component {
  render(){
    return(
      <section id='home'>
        <div>
          <Header/>
          <div className="space"> </div>
          <Slide/>
          <About/>
          <Services/>
          <Customers/>
          <Management/>
          <Contact/>
          <Footer/>
        </div>
      </section>
    )
  }
}

export default Home;
