import en from './dictionaries/en';
import es from './dictionaries/es';
import cn from './dictionaries/cn';

export const languages = [
  { id: 'es', name: 'Español'},
  { id: 'en', name: 'English'}
];

export const dictionaries = { en, es, cn };
