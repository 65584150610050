import React from 'react';
import axios from 'axios';
import {Form, Button, Modal} from 'react-bootstrap';

import success from "./images/ready.png";
import bad from "./images/bad.png";
import contact from "./images/contact.png";
// import china from "./images/china.png";
// import bolivia from "./images/bolivia.png";
import hongkong from "./images/hongkong.png";

import { Text } from "./Text";
// import { OfficesMap } from "./OfficesMap";


class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      name: '',
      lastName: '',
      country: '',
      email: '',
      description: '',
      deliverySuccess: false,
      deliveryError: false,
      showSuccessModal: false,
    };

    this.state = this.initialState
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', () => this.updateDimensions());
  }

  updateDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({
        zoom: 5,
        mapCenter: { lat: 26.156312, lng: 117.9697903 }
      })
    }
    else {
      this.setState({
        zoom: 1,
        mapCenter: { lat: -5.0448849, lng: 29.5397397 }
      })
    }
  };

  changeName = (event) => {
    this.setState({
      name: event.target.value
    })
  };

  changeLastName = (event) => {
    this.setState({
      lastName: event.target.value
    })
  };

  changeCountry = (event) => {
    this.setState({
      country: event.target.value
    })
  };

  changeEmail = (event) => {
    this.setState({
      email: event.target.value
    })
  };

  changeDescription = (event) => {
    this.setState({
      description: event.target.value
    })
  };

  validEmail = () => {
    return (
      (this.state.email !== '') && (/\S+@\S+\.\S+/.test(this.state.email))
    )
  };

  allFilled = () => {
    return (
      (this.state.name !== '') &&
      (this.state.lastName !== '') &&
      (this.state.country !== '') &&
      (this.validEmail())
    )
  };

  clearAll = () => {
    this.setState(this.initialState)
  };

  toggleDeliverySuccess = () => {
    this.setState({
      deliverySuccess: !this.state.deliverySuccess,
    }, () => {
      setTimeout(() => {
        this.toggleShowSuccessModal();
        this.setState({
          deliverySuccess: false,
        })
      }, 4000)
    })
  };

  toggleDeliveryError = () => {
    this.setState({
      deliveryError: !this.state.deliveryError,
    }, () => {
      setTimeout(() => {
        this.toggleShowSuccessModal();
        this.setState({
          deliverySuccess: false,
        })
      }, 4000)
    })
  };

  toggleShowSuccessModal = () => {
    this.setState({
      showSuccessModal: !this.state.showSuccessModal,
    })
  };

  sendEmail = () => {
    const apiUrl = "https://vaeeph07l5.execute-api.us-east-1.amazonaws.com/default/prymegroup-ses-service";

    axios.post(apiUrl, {
      'name-input': this.state.name,
      'last-name-input': this.state.lastName,
      'email-input': this.state.email,
      'country-input': this.state.country,
      'description-input': this.state.description,
    }, {
      headers: {
        'access-control-allow-origin': '*',
      }
    })
      .then((response) => {
        this.clearAll();
        this.toggleShowSuccessModal();
        this.toggleDeliverySuccess();

      })
      .catch((error) => {
        this.clearAll();
        this.toggleShowSuccessModal();
        this.toggleDeliveryError();
      })
  };

  send = (event) => {
    event.preventDefault();

    if (!this.allFilled())
      return;

    this.sendEmail();
  };

  render() {
    return(
      <section id='contact'>
        <div className="pt-5 mx-auto text-center">
          <img src={contact} alt='concact'/>
          <h1 className="font-weight-light display-5 mt-4 mb-3 text-center "><Text tid='contact.title'/></h1>
          <div className="container text-center">
            <Form className="mt-4">
              <Form.Row >
                <div className="col-xs-12 col-sm-12 col-md-6 mb-4 px-3">
                  <Form.Label><Text tid='contact.name'/></Form.Label>
                  <Form.Control
                    name='name-input'
                    value={this.state.name}
                    onChange={ (event) => this.changeName(event) }
                  />
                </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 mb-4 px-3">
                    <Form.Label><Text tid='contact.last_name'/></Form.Label>
                    <Form.Control
                      name='last-name-input'
                      value={this.state.lastName}
                      onChange={ (event) => this.changeLastName(event) }
                    />
                  </div>
              </Form.Row>

              <Form.Row >
                <div className="col-xs-12 col-sm-12 col-md-6 mb-4 px-3">
                  <Form.Label><Text tid='contact.email'/></Form.Label>
                  <Form.Control
                    type="email"
                    name='email-input'
                    value={this.state.email}
                    onChange={ (event) => this.changeEmail(event) }
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 mb-4 px-3">
                  <Form.Label><Text tid='contact.country'/></Form.Label>
                  <Form.Control
                    name='country-input'
                    value={this.state.country}
                    onChange={ (event) => this.changeCountry(event) }
                  />
                </div>
              </Form.Row>

              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label><Text tid='contact.desc'/></Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name='description-input'
                  value={this.state.description}
                  onChange={ (event) => this.changeDescription(event) }
                />
              </Form.Group>

              <Modal show={this.state.showSuccessModal}>
                <Modal.Dialog>
                  <Modal.Body>
                    { this.state.deliverySuccess && <img src={success} alt='success'/> }
                    { this.state.deliveryError && <img src={bad} alt='success'/> }
                  </Modal.Body>
                </Modal.Dialog>
              </Modal>

              <Button
                className="btn color-button btn-lg text-center mb-4 mt-3"
                variant="bg-green"
                type="submit"
                href="#contactanos"
                disabled={ !this.allFilled() }
                onClick={ (event) => this.send(event)}>
                <Text tid='cta.submit'/>
              </Button>
            </Form>

            <div className="row container text-center mx-auto pt-3 ">
              

              {/* <div className="col-xs-12 col-sm-12 col-md-4 mb-5">
                <img className="mb-3" src={china} alt='benefit1'/>
                <div className="texto_azul"><Text tid='offices.china.name'/></div>
                <div className="texto_contacto"><Text tid='offices.china.address1'/></div>
                <div className="texto_contacto"><Text tid='offices.china.address2'/></div>
                <div className="texto_contacto"><Text tid='offices.china.address3'/></div>
                <div className="texto_contacto"><Text tid='offices.phone'/>: +86 21 6456 3356</div>
                <div className="texto_contacto"><Text tid='offices.email'/>: <a className='mail' href="info@prymegroup.com" target="_blank" rel="noopener noreferrer">info@prymegroup.com</a>
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-4 mb-5">
                <img className="mb-3" src={bolivia} alt='benefit2'/>
                <div className="texto_azul"><Text tid='offices.bolivia.name'/></div>
                <div className="texto_contacto"><Text tid='offices.bolivia.address1'/></div>
                <div className="texto_contacto"><Text tid='offices.bolivia.address2'/></div>
                <div className="texto_contacto"><Text tid='offices.phone'/>: +591 3 3337254</div>
                <div className="texto_contacto"><Text tid='offices.email'/>: <a className='mail' href="info@prymegroup.com" target="_blank" rel="noopener noreferrer">info@prymegroup.com</a>
                </div>
              </div> */}

              <div className="col-xs-12 col-sm-12 col-md-12 mb-5">
                <img className="mb-3" src={hongkong} alt='benefit3'/>
                <div className="texto_azul"><Text tid='offices.hong_kong.name'/></div>
                <div className="texto_contacto"><Text tid='offices.hong_kong.address1'/></div>
                <div className="texto_contacto"><Text tid='offices.hong_kong.address2'/></div>
                {/* <div className="texto_contacto"><Text tid='offices.phone'/>: +852 91348520</div> */}
                <div className="texto_contacto"><Text tid='offices.email'/>: <a className='mail' href="info@prymegroup.com" target="_blank" rel="noopener noreferrer">info@prymegroup.com</a>
                </div>
              </div>
            </div>
          </div>

          {/* <OfficesMap mapCenter={this.state.mapCenter} zoom={this.state.zoom} /> */}
        </div>

      </section>
    )
  }
}

export default Contact;