const cn = {
  nav_bar: {
    who_are_we: "公司简介",
    what_do_we_do: "公司业务",
    clients: "客户",
    procurement: "采购流程",
    contact_us: "联系我们",
    language: '语言'
  },
  carousel: {
    development: "在完全互联的世界里",
    offices: '我们努力奋斗',
    quality: '提供您所需要的',
    commitment: '责任和承诺',
  },
  who_are_we: {
    title: '關於我們',
    desc: '普瑞米集团始建于2006年，最初是一家专注于建筑材料采购的公司。之后，我们迅速与亚洲各地的工厂，诸如台湾、中国、日本、韩国、越南、马来西亚和印度建立了联系.',
    desc2: '2009年，我们发展成为一家供应链管理公司，引领建材行业，同时也涉足零售和高科技行业.',
    desc3: '2014年，我们开发并推出了我们的第一个品牌，将质量和创新作为我们运营的核心价值观.',

    staff: '我们拥有跨职能、多元化的员工，拥有满足您要求的资源和经验.',
    offices: '我们在亚洲和拉丁美洲都拥有办事处,可以使您轻松接触到一个庞大的供应商、客户和市场网络.',
    commitment: '我们致力于通过努力实现我们的核心价值观：诚信、领导、责任和创新.',
    statistics: {
      years: '年的市场经验',
      cases: '例成功的经验',
      clients: '% 的回头客率',
      countries: '个国家'
    }
  },
  what_do_we_do: {
    title: '公司业务',
    catering:{
      title: '供应链管理',
      desc: '我们负责提供您所需的产品，从设计、材料选择和生产商，到开发、认证、测试和包装. 我们的目标是为您提供“端到端”、理想、高效的解决方案.',
    },
    logistic: {
      title: '定制物流',
      desc: '我们随时随地为您提供您所需要的产品，并提供灵活的仓储、专业的组装、货物的监督和拼箱等服务，整合和便利所有流程，为您优化供应链绩效.',
    },
    commercial: {
      title: '商业代表',
      desc: '我们运营的平台非常活跃，您可以通过我们的合作伙伴网络，依赖我们的运营基础设施和工作团队，在多个亚洲市场销售和推广您的产品.'
    }
  },
  clients: {
    title: '客户',
    desc: '普瑞米自豪地拥有2个令人兴奋的原创品牌。',
    desc2: ' 我们曾合作过的知名品牌',
    trx: '持久耐用的面板天花板解决方案',
    elux: '设计和优化新一代灯具.'
  },
  management: {
    title: '采购流程',
    client : '客户',
    requirements: '产品规格',
    design: '产品设计',
    selection: '供应商选择',
    terms_and_conditions: '合同和条款',
    development: '产品开发',
    control: '生产控制',
    delivery: '物流和配送',
  },
  contact: {
    title: '联系我们',
    name: '姓名',
    last_name: '姓氏',
    email: '电子邮件',
    country: '国家',
    desc: '您的需求'
  },
  social: {
    title: '社會的',
    challenge: '¿小朋小朋小朋?'
  },
  cta: {
    go_to_contact: '联系我们',
    go_to_contact2: '在這裡與我們聯繫',
    submit: '准备发送'
  },
  offices: {
    china: {
      name: "中华人民共和国",
      address1: "中国上海徐汇区天钥桥路567号森本大厦624室",
      address2: "邮编：200030",
    },
    bolivia: {
      name: "玻利维亚",
      address1: "Calle Ecuador 191, Zona UAGRM",
      address2: "Santa Cruz de la Sierra"
    },
    hong_kong: {
      name: "香港",
      address1: "香港特别行政区湾仔区骆克道300号侨富商业大厦12楼A室",
    },
    phone: "电话",
    email: "电子邮箱"
  }
};

export default cn;