import React, {useContext} from "react";

import { languages } from "./languages";
import { LanguageContext } from "./language_context";
import { Dropdown } from "react-bootstrap";
import spanish from "../images/spanish.png";
import english from "../images/english.png";
import chinese from "../images/chinese.png";

export default function LanguageSelector() {
  const languageContext = useContext(LanguageContext);

  const handleLanguageChange = (_language) => {
    const selectedLanguage = languages.find(language => language.id === _language);
    languageContext.setLanguage(selectedLanguage);
  };

  const languageIcon = () => {
    switch(languageContext.language.id) {
      case 'en':
        return english;
      case 'cn':
        return chinese;
      default:
        return spanish;
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle className="language" id="dropdown-basic">
        <img src= {languageIcon()} alt='language' />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleLanguageChange('es')}>
          <img className="mb-3" src={spanish} alt='spanish'/>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleLanguageChange('en')}>
          <img className="mb-3" src={english} alt='english'/>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
