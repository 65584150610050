import React from 'react'

import gestion from './images/gestion.png'
import gestion_es from "./images/proceso_es.png";
import gestion_en from "./images/proceso_en.png";

import {Text} from "./Text";
import { useContext } from "react";
import { LanguageContext } from "./languages/language_context";


function Management() {
  const languageContext = useContext(LanguageContext);

  const languageIcon = () => {
    switch(languageContext.language.id) {
      case 'en':
        return gestion_en;
      default:
        return gestion_es;
    }
  };

  return(
    <section id='management'>
      <div className="bg-gris pt-5 pb-3 mx-auto text-center">
        <div className="container text-center">
          <img src={gestion} alt='gestion'/>
          <h2 className="font-weight-light mt-4 mb-3"><Text tid='management.title'/></h2>

          <div className="row mx-auto  mb-4 ">
            <div className="col-lg-12 ">
              <img className="mb-3 gestion_image " src={languageIcon()} alt='gestion'/>
            </div>
          </div>
              {/* <div className="col-xs-12 col-sm-12 col-md-4 my-3">
                <img className="mb-3" src={gestion0} alt='products1'/>
                <p className="texto_bajada "><span className="texto_azul">1.</span><Text tid='management.client'/></p>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-4 my-3">
                <img className="mb-3" src={gestion1} alt='products1'/>
                <p className="texto_bajada "><span className="texto_azul">2.</span><Text tid='management.requirements'/></p>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-4 my-3">
                <img className="mb-3" src={gestion3} alt='products1'/>
                <p className="texto_bajada "><span className="texto_azul">3.</span><Text tid='management.design'/></p>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-4 my-3">
                <img className="mb-3" src={gestion2} alt='products1'/>
                <p className="texto_bajada "><span className="texto_azul">4.</span><Text tid='management.selection'/></p>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-4 my-3">
                <img className="mb-3" src={gestion4} alt='products1'/>
                <p className="texto_bajada "><span className="texto_azul">5.</span><Text tid='management.terms_and_conditions'/></p>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-4 my-3">
                <img className="mb-3" src={gestion5} alt='products1'/>
                <p className="texto_bajada "><span className="texto_azul">6.</span><Text tid='management.development'/></p>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-4 mt-3">
                <img className="mb-3" src={gestion7} alt='products2'/>
                <p className="texto_bajada"><span className="texto_azul">7.</span><Text tid='management.control'/></p>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-4 mt-3 ">
                <img className="mb-3" src={gestion8} alt='products3'/>
                <p className="texto_bajada"><span className="texto_azul">8.</span><Text tid='management.delivery'/></p>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-4 mt-3 ">
                <img className="mb-3" src={gestion0} alt='products4'/>
                <p className="texto_bajada"><span className="texto_azul">9.</span><Text tid='management.client'/></p>
            </div> */}

          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="text-center">
              <a className="btn color-button btn-lg mb-3" href="#contact"><Text tid='cta.go_to_contact'/></a>
            </div>
          </div>

        </div>
      </div>

    </section>
  )
}

export default Management;