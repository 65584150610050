import React from 'react'

import about1 from './images/about1.png'
import about2 from './images/about2.png'
import about3 from './images/about3.png'
import world from './images/world.png'
import CountUp from "react-countup"; //contador

import { Text, RichText } from "./Text";


class About extends React.Component {
  render() {
    return(
      <section id='about'>
        <div className="container pt-5  text-center ">
          <img src={world} alt='world'/>
          <h1 className=" font-weight-light display-5 my-3 text-center "><Text tid='who_are_we.title'/></h1>

          <div className="row container text-center mx-auto texto_iconos mt-4 mb-4">
            <div className="col-xs-12 col-sm-12 col-md-4">
              <RichText classes="text-center " tid='who_are_we.desc'/>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4">
              <RichText classes="text-center" tid='who_are_we.desc2'/>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4">
              <RichText classes="text-center" tid='who_are_we.desc3'/>
            </div>
          </div>

          <div className="row container text-center mx-auto texto_iconos mb-2">
            <div className="col-xs-12 col-sm-12 col-md-4">
              <img className="mb-2 " src={about1} alt='benefit1'/>
              <RichText  tid='who_are_we.staff'/>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4">
              <img className="mb-2 texto_iconos" src={about2} alt='benefit2'/>
              <RichText  tid='who_are_we.offices'/>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4">
              <img className="mb-2 texto_iconos" src={about3} alt='benefit3'/>
              <RichText  tid='who_are_we.commitment'/>
            </div>
          </div>

        </div>

        <div className=" bg-degrade py-4">
          <div className="row text-center container mx-auto ">
            <div className="col-xs-12 col-sm-12 col-md-3 my-2">
              {/* <h1 className="statistic white">15</h1> */}
              <div className='margin-bottom'></div>
              <CountUp className="statistic white" end={17} duration={30} separator={ '.'}/>
              <p className="white-text"><Text tid='who_are_we.statistics.years'/></p>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-3 my-2">
              {/* <h1 className="statistic white">400+</h1> */}
              <p className="white-text"><Text tid='who_are_we.statistics.more'/></p>
              <CountUp className="statistic white" end={400} duration={30} separator={ '.'}/>
              <p className="white-text"><Text tid='who_are_we.statistics.cases'/></p>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-3 my-2">
              {/* <h1 className="statistic white">60</h1> */}
              <div className='margin-bottom'></div>
              <CountUp className="statistic white" end={80} duration={30} separator={ '.'}/>
              <p className="white-text"><Text tid='who_are_we.statistics.clients'/></p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 my-2">
              {/* <h1 className="statistic white">10+</h1> */}
              <p className="white-text"><Text tid='who_are_we.statistics.more'/></p>
              <CountUp className="statistic white" end={10} duration={30} separator={ '.'}/>
              <p className="white-text"><Text tid='who_are_we.statistics.countries'/></p>
            </div>
          </div>
        </div>

        {/* <div className=" bg-degrade py-4">
          <div className="row text-center container mx-auto ">
            <div className="col-xs-12 col-sm-12 col-md-3 ">
              <CountUp className="statistic" end={15} duration={10} separator={ '.'}/>
              <p className="white-text"><Text tid='who_are_we.statistics.years'/></p>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-3 ">
              <CountUp className="statistic" end={400} duration={50} separator={ '.'}/>
              <p className="white-text"><Text tid='who_are_we.statistics.cases'/></p>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-3 ">
              <CountUp className="statistic" end={ 60 } duration={20} separator={ '.'}/>
              <p className="white-text"><Text tid='who_are_we.statistics.clients'/></p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 ">
              <CountUp className="statistic" end={ 10 } duration={10} separator={ '.'}/>
              <p className="white-text"><Text tid='who_are_we.statistics.countries'/></p>
            </div>
          </div>
        </div>*/}


      </section>
    )
  }
}

export default About;