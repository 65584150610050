import React from 'react'

import {RichText, Text} from "./Text";
import customer from "./images/customer.png";
import trx from "./images/trx.png";
import elux from "./images/elux.png";
import logo1 from "./images/todotix.png";
import logo2 from "./images/tecnopor.png";
import logo3 from "./images/tanax.png";
import logo4 from "./images/sofia.png";
import logo5 from "./images/paris_cencosud.png";
import logo6 from "./images/madisa.png";
import logo7 from "./images/jumbo_chile.png";
import logo8 from "./images/indistris_belen.png";
import logo9 from "./images/imex_solutions.png";
import logo10 from "./images/ghs.png";
import logo11 from "./images/fridosa.png";
import logo12 from "./images/easy_chile.png";
import logo13 from "./images/cbn_01.png";
import logo14 from "./images/gladymar_1.png";
import logo15 from "./images/carmax.png";
import logo16 from "./images/bomberos_chile.png";
import logo17 from "./images/tecdisa.png";
import logo18 from "./images/salcobrand.png";
import logo19 from "./images/grupo_retail.png";
import logo20 from "./images/transbank.png";
import logo21 from "./images/terreno.png";
import logo22 from "./images/redcompra.png";
import logo23 from "./images/inspectra.png";
import logo24 from "./images/grupo_franco.png";
import logo25 from "./images/canon.png";
import logo26 from "./images/byr_foods.png";
import logo27 from "./images/plussteel.png";
import logo28 from "./images/las_lomas.png";
import logo29 from "./images/gmasivos.png";
import logo30 from "./images/americanChemical.png";
import logo31 from "./images/magellangroup.png";
import logo32 from "./images/cencosud.png";
import logo33 from "./images/limax.png";



class Customers extends React.Component {
  render() {
    return(
      <section id='customers'>
        <div className=" container py-5 text-center ">
          <img src={customer} alt='services'/>
          <h1 className="  font-weight-light display-5 mt-2 mb-3 text-center "><Text tid='clients.title'/></h1>
          <RichText classes="h4 font-weight-light mb-3 mx-auto text-center" tid='clients.desc'/>

          <div className="row text-center pb-2">
            <div className="col-xs-12 col-sm-12 col-md-6 px-5 texto-trx">
              <img  className="my-4 " src={trx} alt='trx'/>
              <p classes="px-5">
                <Text tid='clients.trx'/>
              </p>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 px-5 texto-elux ">
              <img className="my-4 " src={elux} alt='elux'/>
              <p classes=" px-5">
                <Text tid='clients.elux'/>
              </p>
            </div>
          </div>

          <hr className="line my-5"></hr>

          {/* LOGOS  height="100" width="250" alt="" */}   
          <div className="row text-center pt-4 m-5 ">
            <RichText classes="h4 text-center font-weight-light mx-auto " tid='clients.desc2'/>
          </div>

          <div className="row text-center slider">
              <div className="slide-track">

             <div className="slide col-xs-12 col-sm-12 col-md-3 ">
                <img   src={logo1} alt='todotix'/>
             </div>

             <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo32} alt='cencosud'/>
              </div>

             <div className="slide col-xs-12 col-sm-12 col-md-3 ">
                <img src={logo2} alt='tecnopor'/>
             </div>

             <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo5} alt='elux'/>
              </div>

             <div className="slide col-xs-12 col-sm-12 col-md-3 ">
                <img  src={logo3} alt='tanax'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo20} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img src={logo4} alt='sofia'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img   src={logo7} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo6} alt='elux'/>
              </div>


              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo8} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo12} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo9} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo15} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo10} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo11} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo14} alt='elux'/>
              </div>


              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo13} alt='elux'/>
              </div>


              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo16} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo17} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo18} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo19} alt='elux'/>
              </div>


              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo21} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo22} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo23} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo24} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo25} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo26} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo27} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo28} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo29} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo30} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo31} alt='elux'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo33} alt='limax'/>
              </div>

              <div className="slide col-xs-12 col-sm-12 col-md-3 ">
                <img   src={logo1} alt='trx'/>
             </div>

             <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo32} alt='cencosud'/>
              </div>

             <div className="slide col-xs-12 col-sm-12 col-md-3 ">
                <img src={logo2} alt='elux'/>
             </div>

             <div className="slide col-xs-12 col-sm-12 col-md-3 ">
               <img  src={logo5} alt='elux'/>
              </div>


              

            </div>
          </div>



          </div>

      </section>
    )
  }
}

export default Customers;