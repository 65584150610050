import React from 'react'

import services from './images/services.png'
import services1 from './images/services1.png'
import services2 from './images/services2.png'
import services3 from './images/services3.png'

import { Text, RichText } from "./Text";


class Services extends React.Component {
  render() {
    return(
      <section id='services'>
        <div className=" bg-perla">
        <div className="container pt-5 pb-4  text-center">
          <img src={services} alt='services'/>
          <h1 className="animated bounce delay-1s fadeInDown font-weight-light display-5 mt-3 mb-3 text-center "><Text tid='what_do_we_do.title'/></h1>

          <div className="row container text-left mx-auto mt-3 mb-5 ">
            <div className="col-xs-12 col-sm-12 col-md-4 px-4">
              <img className="my-4" src={services1} alt='benefit1'/>
              <h3 className="mt-2 mb-4"><Text tid='what_do_we_do.catering.title'/></h3>
              <div>
                <RichText tid='what_do_we_do.catering.desc'/>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4 px-4">
              <img className="my-4" src={services2} alt='benefit2'/>
              <h3 className="mt-2 mb-4"><Text tid={'what_do_we_do.logistic.title'}/></h3>
              <RichText tid='what_do_we_do.logistic.desc'/>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4 px-4">
              <img className="my-4" src={services3} alt='benefit3'/>
              <h3 className="mt-2 mb-4"><Text tid={'what_do_we_do.commercial.title'}/></h3>
              <RichText tid='what_do_we_do.commercial.desc'/>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="text-center">
              <a className="btn color-button btn-lg mb-4" href="#contact"><Text tid='cta.go_to_contact'/></a>
            </div>
          </div>
        </div>
          </div>

      </section>
    )
  }
}


export default Services;