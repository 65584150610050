import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';

import { Text } from "./Text";
import LanguageSelector from "./languages/language_selector";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navExpanded: false,
    }
  }

  setNavExpanded = (expanded) => {
    this.setState({
      navExpanded: expanded,
    })
  };

  setNavClosed = () => {
    this.setState({
      navExpanded: false,
    })
  };

  render() {
    return(
      <div className="fixed-top">
        <Navbar bg="light" expand="lg" onToggle={this.setNavExpanded} expanded={this.state.navExpanded} >
          <Navbar.Brand href="#home">
            <div className="logo" />
            <div className="logo_mobile" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto" onSelect={this.setNavClosed}>
              <Nav.Link className="navbar" href="#about"><Text tid='nav_bar.who_are_we'/></Nav.Link>
              <Nav.Link className="navbar" href="#services"><Text tid='nav_bar.what_do_we_do'/></Nav.Link>
              <Nav.Link className="navbar" href="#customers"><Text tid='nav_bar.clients'/></Nav.Link>
              <Nav.Link className="navbar" href="#management"><Text tid='nav_bar.procurement'/></Nav.Link>
              <Nav.Link className="navbar" href="#contact"><Text tid='nav_bar.contact_us'/></Nav.Link>
              <LanguageSelector/>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}

export default Header;
