import _ from 'lodash';
import React, { useContext } from "react";

import { LanguageContext } from "./languages/language_context";

export function Text(props) {
  const languageContext = useContext(LanguageContext);
  return _.get(languageContext.dictionary, props.tid, '');
}

export function RichText(props) {
  const languageContext = useContext(LanguageContext);
  return (
    <p className={props.classes} dangerouslySetInnerHTML={{__html: _.get(languageContext.dictionary, props.tid, '') }}/>
  )
}